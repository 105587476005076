.login_page {
    width: 100vw;
    height: 100vh;
}

.login_page .adm-list-item {
    background-color: #FFFFFF;
    padding: 10px 15px;
    margin-top: 15px;
}

* {
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
    background-color: #F3F3F3;
}

:root {
    --adm-color-background: none;
    --adm-border-color: none;
}

.read {
    background-color: #00DC9E;
}

.unread {
    background-color: #F53500;
}

.icon_01 {
    background-color: #DAF7F9;
    border: #2ABCC7 solid 1px;
    color: #2ABCC7
}

.icon_02 {
    background-color: #FFEAD9;
    border: #EF760C solid 1px;
    color: #EF760C
}

.icon_03 {
    background-color: #FFECEC;
    border-radius: 8px;
    border: #FF5757 solid 1px;
    color: #FF5757
}

.icon_04 {
    background-color: #FEF6E1;
    border: #EFA30A solid 1px;
    color: #EFA30A
}

.icon_05 {
    background-color: #D4EBFF;
    border: #339EF9 solid 1px;
    color: #339EF9
}

.page {
    min-height: calc(100vh - 30px);
    padding: 15px;
}

.approved {
    border: #339EF9 solid 1px;
    color: #339EF9;
    background-color: #D4EBFF;
}

.notapproved {
    border: #EF760C solid 1px;
    color: #EF760C;
    background-color: #FFEAD9;
}

.record {
    display: flex;
    align-items: center;
}

.record>img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.record>div {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #3396FB;
}

#root .adm-nav-bar {
    background-color: #FFFFFF;
    padding: 0 10px;
}

.adm-nav-bar-title {
    font-family: 思源黑体 CN;
    font-size: 18px;
    color: #333333;
}

.adm-popup .adm-popup-close-icon {
    top: 13px;
    right: 15px;
    font-size: 20px;
}

.adm-form .adm-list.adm-list {
    --padding-left: 0;
    --padding-right: 0;
}

.adm-form .adm-list-item-content {
    align-items: center;
    justify-content: space-between;
}

.adm-form .adm-list-item-content-main {
    padding: 0;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
    font-size: 16px
}

.adm-form .adm-list-item-content-main .adm-form-item-child-inner,
.adm-form .adm-list-item-content-main .adm-form-item-feedback-error {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    text-align: right;

}

.adm-form-item-child-inner #pro_name,
.adm-form-item-child-inner #sup_name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adm-form-item-child-inner #pro_name>div:nth-of-type(1),
.adm-form-item-child-inner #seal_name>div:nth-of-type(1),
.adm-form-item-child-inner #sup_name>div:nth-of-type(1) {
    text-align: left;
}

.adm-form-item-child-inner div span {
    margin-right: 8px;
}

.adm-form .adm-list-item-content-main .adm-space-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
}

.adm-form .adm-list-item-content-main .adm-space-item img {
    width: 15px;
    height: 15px;
}

.adm-form .adm-list-item-content-arrow {
    display: none;
}

/* .adm-popup-body {
    background-color: #FFFFFF;
    border-radius: 10px 15px 0 0;
} */

.popup_select {
    padding: 15px;
    overflow: hidden;
}

.popup_select>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 16px;
    text-align: center;
    color: #333333;
    margin-bottom: 15px;
}

.popup_select>div:nth-of-type(3) {
    margin: 15px 0;
    height: 30vh;
    overflow-y: scroll;
}

.popup_select>div:nth-of-type(2) .adm-input-element {
    font-family: 思源黑体 CN;
    font-size: 15px;
    color: #999999;
}

.popup_select>div:nth-of-type(3) .adm-list-item-content-main {
    padding: 5px 0;
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #999999;
}

.adm-popup-body .adm-list {
    --border-top: none;
    --border-bottom: none;
}

.adm-popup-body .adm-list-item-content {
    border-top: none
}

/* content.js */

.new_content {
    background-color: #FFFFFF;
    padding: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new_content>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}

.new_content>div:nth-of-type(1)>img {
    width: 30px;
    margin-right: 5px;
}

.new_content>div:nth-of-type(1)>div {
    font-family: 思源黑体 CN;
    font-size: 18px;
    color: #333333;
}

.content_tab {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.content_tab>div {
    width: 33%;
    padding: 15px 0;
    font-family: 思源黑体 CN;
    font-size: 16px;
    text-align: center;
    color: #333333;
}

.activity {
    border-bottom: #3396FB solid 3px;
}

.content_list>div {
    margin-top: 15px;
}

.content_list>div>div:nth-of-type(1) {
    background-color: #FFFFFF;
    margin-bottom: 2px;
}

.content_list>div>div:nth-of-type(2) {
    background-color: #FFFFFF;
    padding: 15px 15px 0;
    margin-bottom: 2px;
    overflow: hidden;
}

.content_list>div>div:nth-of-type(2)>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    margin-bottom: 15px;
}

.content_list>div>div:nth-of-type(2)>div>div:nth-of-type(1) {
    max-width: 105px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.content_list>div>div:nth-of-type(2)>div>div:nth-of-type(2) {
    max-width: 250px;
    /* width: calc(100vw - 150px); */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.content_list>div>div:nth-of-type(3) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #FFFFFF;
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #999999;
}

/* addconfig.js */

.form_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    margin-top: 15px;
}

.form_title>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}

.form_title>div:nth-of-type(1)>img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.form_title>div:nth-of-type(1)>div {
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #333333;
}

.form_title>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #999999;
}

.userInfo {
    display: flex;
    align-items: center;
    padding: 15px;
}

.userInfo>div:nth-of-type(1) {
    text-align: center;
    margin-right: 30px;
}

.userInfo>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3396FB;
    margin-bottom: 2px;
    font-family: 思源黑体 CN;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    color: #FFFFFF;
}

.userInfo>div:nth-of-type(1)>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
}

.userInfo>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
}

.form_content .adm-list-item {
    background-color: #FFFFFF;
    padding: 15px;
    margin-bottom: 15px;
}

.form_content .adm-form-item-label {
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #333333;
}

.form_content .adm-input .adm-input-element,
.form_content .adm-text-area .adm-text-area-element {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
}

.copyList {
    display: flex;
    margin-top: 2px;
}

.copyList .empty {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F5F6F8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyList .ccPersonList {
    display: flex;
}

.ccPersonList .ccPerson {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.ccPersonList .ccPerson>div {
    margin-right: 10px;
    text-align: center;
}

.ccPersonList .ccPerson>div>div:nth-of-type(1) {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3396FB;
    margin-bottom: 2px;
    font-family: 思源黑体 CN;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    color: #FFFFFF;
}

.ccPersonList .ccPerson>div>div:nth-of-type(1)>svg {
    position: absolute;
    right: -2px;
    top: -2px;
}

.ccPersonList .ccPerson>div>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
}

.plate {
    border-bottom: 2px solid #FFFFFF;
}

.plate>.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFEFDB;
    padding: 15px;
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #333333;
}

.plate>.title>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}

.plate>.title>div:nth-of-type(2)>div {
    margin-right: 10px;
}

.plate>div:nth-of-type(2) {
    border-bottom: 2px solid #F3F3F3;
}

.plate>div:nth-of-type(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    padding: 15px;
    background-color: #FFFFFF;
    margin-bottom: 15px;
}

.plate>div:nth-of-type(3)>div {
    margin-left: 5px;
}

.dataSource {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 0 15px;
}

.form_content .dataSource .adm-list-item {
    padding: 0px;
    margin-bottom: 0;
    width: calc(100vw - 55px);
}

.dataSource .adm-list-item .adm-input-element {
    text-align: right;
}

.dataSource .adm-list-item .adm-form-item-label {
    font-size: 14px;
}

.dataSource .adm-form-item-vertical .adm-list-item-title {
    padding: 12px 0;
}

.process {
    background-color: #FFFFFF;
}

.process>div:nth-of-type(1) {
    padding: 15px;
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #333333;
    border-bottom: #F3F3F3 solid 1px;
}

.process>div:nth-of-type(2) {
    padding: 15px;
    display: flex;
    align-items: center;
}

.process>div:nth-of-type(2)>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    margin-right: 15px;
}

.process>div:nth-of-type(2)>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 4px;
}

.process>div:nth-of-type(3) {
    padding: 0 15px;
    overflow: hidden;
    border-bottom: #F3F3F3 solid 1px;
}

.process>div:nth-of-type(3)>div {
    margin-bottom: 10px;
}

.process>div:nth-of-type(3)>div>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}

.process>div:nth-of-type(3)>div>div:nth-of-type(2) {
    display: flex;
    justify-content: center;
}

.process>div:nth-of-type(3)>div>div:nth-of-type(2)>img {
    width: 10px;
    display: flex;
    justify-content: center;
}

.process>div:nth-of-type(3)>div>div>div:nth-of-type(1) {
    text-align: center;
    margin-right: 30px;
}

.process>div:nth-of-type(3)>div>div>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3396FB;
    margin-bottom: 2px;
    font-family: 思源黑体 CN;
    font-size: 13px;
    text-align: center;
    line-height: 40px;
    color: #FFFFFF;
}

.process>div:nth-of-type(3)>div>div>div:nth-of-type(1)>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
}

.process>div:nth-of-type(3)>div>div>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
}

.process>div:nth-of-type(3)>div>div>div:nth-of-type(2)>div>div:nth-of-type(1) {
    width: 70px;
}

.process>div:nth-of-type(3)>div>div>div:nth-of-type(2)>div>div:nth-of-type(2) {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.process>div:nth-of-type(3)>div>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    padding: 2px 5px;
    border-radius: 4px;
}

.copy {
    background-color: #FFFFFF;
    padding: 15px;
}

.copy>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #333333;
    margin-bottom: 15px;
}

.copy>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.copy>div:nth-of-type(2)>div>div:nth-of-type(1) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3396FB;
    margin-bottom: 2px;
    font-family: 思源黑体 CN;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    color: #FFFFFF;
    margin-right: 30px;
}

.copy>div:nth-of-type(2)>div>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
}

.copy>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}

.complete,
.in_process {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 5px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.complete>img,
.in_process>img {
    width: 24px;
    margin-right: 10px;
}

.complete>div,
.in_process>div {
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #333333;
}

.dispose {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 30px);
    background-color: #FFFFFF;
    box-shadow: 0px 2px 5px #000;
    padding: 15px;
}

.dispose>div:nth-of-type(1) {
    background-color: #3396FB;
    padding: 5px 0;
    border-radius: 5px;
    font-family: 思源黑体 CN;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.dispose>div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dispose>div:nth-of-type(2)>div {
    width: calc(50% - 10px);
    border: #999999 solid 1px;
    border-radius: 5px;
    padding: 5px 0;
    font-family: 思源黑体 CN;
    font-size: 16px;
    text-align: center;
    color: #333333;
}

.opinion {
    padding: 15px;
}

.opinion>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 16px;
    text-align: center;
    color: #333333;
    margin-bottom: 15px;
}

.opinion>div:nth-of-type(1)>span {
    color: #F53500;
}

.opinion .adm-text-area {
    background-color: #F4F5F9;
    border-radius: 4px;
    height: 25vh;
    margin-bottom: 15px;
}

.opinion .adm-text-area .adm-text-area-element {
    height: 100%;
}

.opinion>div:nth-of-type(3) {
    background-color: #3396FB;
    padding: 8px 0;
    border-radius: 5px;
    font-family: 思源黑体 CN;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}

/* uasrAttach.js*/

.fileList .ant-upload img {
    width: 86px;
}

.fileList>div:nth-of-type(1) {
    display: flex;
    align-items: flex-end;
}

.fileList>div:nth-of-type(1)>div {
    width: calc(100vw - 130px);
    ;
    margin-left: 15px;
}

.fileList>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: #3396FB solid 1px;
    border-radius: 5px;
    padding: 12px;
    margin-top: 15px;
}

.fileList>div:nth-of-type(2)>div:nth-of-type(1) {
    margin-top: 5px;
}

.fileList>div:nth-of-type(2)>div>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #3396FB;
}

.uploadList>div {
    border: #3396FB solid 1px;
    border-radius: 5px;
    padding: 12px;
    margin-top: 15px;
}

.uploadList>div:nth-of-type(1) {
    margin-top: 5px;
}

.uploadList>div>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #3396FB;
    margin-bottom: 5px;
    text-align: left;
}

.uploadList>div>div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 思源黑体 CN;
    font-size: 12px;
    color: #3396FB;
}

/* home.js */

.prompt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prompt>div {
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.prompt>div:nth-of-type(1) {
    background: linear-gradient(145deg, #FEA062, #FD825D);
}

.prompt>div:nth-of-type(2) {
    background: linear-gradient(145deg, #3CC8D0, #1AB191);
}

.prompt>div:nth-of-type(3) {
    background: linear-gradient(145deg, #81C8FC, #4B89E6);
}

.prompt>div>div>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #FFFFFF;
}

.prompt>div>div>div:nth-of-type(2) {
    font-family: D-DIN DIN;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
}

.prompt>div>img {
    width: 32px;
}

.my_process {
    padding: 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-top: 15px;
}

.my_process>div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my_process>div:nth-of-type(1)>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 16px;
    /* font-weight: bold; */
    color: #333333;
}

.my_process>div:nth-of-type(1)>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #3396FB;
}

.my_process>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    margin-top: 10px;
}

.my_process>div:nth-of-type(2)>div>img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.content {
    padding: 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-top: 15px;
}

.content>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 16px;
    /* font-weight: bold; */
    color: #333333;
}

.content>div:nth-of-type(2) {
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    flex-wrap: wrap;
}

.content>div:nth-of-type(2)>div {
    width: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content>div:nth-of-type(2)>div>div:nth-of-type(1) {
    position: relative;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.content>div:nth-of-type(2)>div>div:nth-of-type(1)>img {
    width: 50px;
    height: 50px;
}

.content>div:nth-of-type(2)>div>div:nth-of-type(1)>div {
    position: absolute;
    top: -2px;
    right: -20px;
    padding: 0 4px;
    border-radius: 0 6px 0 6px;
    background-color: #F53500;
    font-size: 12px;
    color: #FFFFFF;
}

.content>div:nth-of-type(2)>div>div:nth-of-type(2) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
}

/*prompt.js*/

.prompt_tabs {
    display: flex;
    justify-content: space-between;
}

.prompt_tabs>svg {
    margin-top: 2px;
    margin-left: -5px;
}

.prompt_tab {
    width: calc(100vw - 60px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.prompt_tab>div {
    width: 82px;
}

.prompt_tab>div>div:nth-of-type(1) {
    display: flex;
    margin-bottom: 10px;
}

.prompt_tab>div>div:nth-of-type(1)>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 16px;
    color: #333333;
}

.prompt_tab>div>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #F53500;
    font-family: 思源黑体 CN;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    color: #FFFFFF;
    margin-top: -5px;
}

.prompt_tab>div>div:nth-of-type(2) {
    width: 20px;
    height: 4px;
    background-color: #3396FB;
    border-radius: 4px;
    margin-left: 20px;
}

.prompt_list>div {
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-top: 15px;
}

.prompt_list>div>div:nth-of-type(1) {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #999999;
    margin-bottom: 10px;
}

.prompt_list>div>div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prompt_list>div>div:nth-of-type(2)>div:nth-of-type(1),
.prompt_list>div>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}

.prompt_list>div>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 10px;
}

.prompt_list>div>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2)>img {
    width: 16px;
    height: 16px;
}

.prompt_list>div>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2)>div {
    font-family: 思源黑体 CN;
    font-size: 14px;
    color: #333333;
    margin-left: 5px;
}

.prompt_list>div>div:nth-of-type(2)>div:nth-of-type(2) {
    border-radius: 4px;
    padding: 0px 4px;
    font-family: 思源黑体 CN;
    font-size: 12px;
}

.adjust_price{
    padding:10px 15px;
}

.price_adjustment {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price_adjustment>div {
    width: 31%;
    font-family: 思源黑体 CN;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.price_adjustment>div:nth-of-type(1) {
    width: 5%;
}

.price_adjustment>div:nth-of-type(3)>div,
.price_adjustment>div:nth-of-type(4)>div {
    display: flex;
    align-items: center;
}

.price_adjustment>div:nth-of-type(3)>div>div:nth-of-type(1),
.price_adjustment>div:nth-of-type(4)>div>div:nth-of-type(1) {
    /* width: 15px;
    height: 15px; */
    margin-right: 12px;
    position: relative;
    padding: 2px 0;
}

.price_adjustment>div:nth-of-type(3)>div>div:nth-of-type(1)>img,
.price_adjustment>div:nth-of-type(4)>div>div:nth-of-type(1)>img {
    width:18px;
    height:13px;
    position: absolute;
    top: 0px;
    right: -10px;
}

.price_adjustment>div:nth-of-type(3)>div>div:nth-of-type(2),
.price_adjustment>div:nth-of-type(4)>div>div:nth-of-type(2) {
    width: 62%;
}